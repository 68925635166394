import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import url from "../../../utils/services/urls.json"
import { getMethodWithToken } from '../../../utils/services/apis';
import PhoneIcon from "../../../assets/images/listingManager/call.svg";
import Mail from "../../../assets/images/listingManager/mail.svg";
// import messageIcon from "../../../assets/images/listingManager/sms.svg";
import DetailWithOwnerList from '../../../Components/commonComponents/CardDeatil/DetailWithOwnerList/DetailWithOwnerList';
import "./PropertyOwnerAssignList.scss";
import { navigateLogin } from '../../../utils/services/common';
import DefaultImage from "../../../assets/images/default-image.svg"


const PropertyAssignList = styled.div`
margin-top: 50px;
`

const PropertyOwnerAssignList = ({ propertyDetailId, selectValue }) => {
    const navigate = useNavigate()
    const [ownerDetails, setOwnerDetails] = useState([])
    const [sliderImage, setSliderImage] = useState([])

    useEffect(() => {
        getPropertyOwnerDetail()
    }, [propertyDetailId])

    const getPropertyOwnerDetail = async () => {
        await getMethodWithToken(url.getPropertyDetail + propertyDetailId + "/").then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setOwnerDetails(response?.data)
                if (response?.data?.length > 0) {
                    response?.data.map((value, index) => (
                        setSliderImage(value?.property_images)
                    ))
                }
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }

    const handleViewDetail = (id, userName, availableDate) => {
        navigate(`detail/${id}`, {
            state: {
                userName: userName,
                availableDate: availableDate
            }
        })
    }
    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }
    return (
        <PropertyAssignList>
            <div className="property-assign-container">
                <div className="property-profile-seacrtion" >
                    <div className="d-flex justify-content-center">
                        <img src={selectValue?.profilePic?.file ? selectValue?.profilePic?.file : DefaultImage} alt="" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="property-owner-heading">{selectValue?.name?.length > 0 && getUpparCaseName(selectValue?.name)}</div>
                    </div>
                    {/* <div className="select-dec">
                        <div className="select-des-value">National Oceanic and Atmospheric Administration Office of the Federal Coordinator for Meteorology</div>
                    </div> */}
                    <div className="d-flex justify-content-center mb-3" style={{ marginTop: "13px", marginLeft: "50px" }}>
                        <div >
                            <img src={PhoneIcon} alt="" />
                        </div>
                        <div className="phone-number">{ownerDetails?.[0]?.Property_owner?.[0]?.contact_number}</div>
                        <div style={{ marginLeft: "20px" }}>
                            <img src={Mail} alt="" />
                        </div>
                        <div className="phone-number">{ownerDetails?.[0]?.Property_owner?.[0]?.email}</div>
                    </div>
                    {/* <div className="d-flex justify-content-center">
                        <div className="box-msg">
                            <img src={messageIcon} alt="" />
                            <div className="message-heading">Message</div>
                        </div>
                    </div> */}
                </div>
                <div className="list-assign-heading">Listings assigned to me</div>
                <div className="owner-list-container">
                    <DetailWithOwnerList ListingDetailsWithOwner={ownerDetails} sliderImage={sliderImage} showRequest={true} onClick={handleViewDetail} />
                </div>
            </div>
        </PropertyAssignList>
    )
}

export default PropertyOwnerAssignList
