import React from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../../Components/commonComponents/navbar/Navbar'
import Sidebar from '../../Components/commonComponents/sidebar/Sidebar'
import UserProfile from '../UserProfile/UserProfile'
import AssignedListings from "../AssignList/AssignedListings/AssignedListings"
import ManagerSubscription from '../ManageSubscriptions/ManagerSubscription/ManagerSubscription'
import ManageRequestList from '../ManageRequest/ManageRequestList/ManageRequestList'
import PropertyOwnerList from '../PropertyOwner/PropertyOwnerList/PropertyOwnerList'
import PaymentCardDetail from '../PaymentOption/PaymentCardDetail/PaymentCardDetail'
import ChatList from '../ChatSeaction/ChatList/ChatList'

const Dashboard = () => {
  const params = useParams()

  return (
    <>
      <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
        <Navbar />
        <div className='d-flex'>
          <div className="Sidebar_control" style={{ overflow: "scroll", width: "37%", height: "100vh", minHeight: "fit-content" }}>
            <Sidebar />
          </div>
          <div className="Sidebar_control" style={{ overflow: "scroll", width: "100%", height: "100vh" }}>
            <div style={{ display: 'inline-block', marginTop: '60px', marginBottom: '5.5vh' }}>
              {
                params.value === "AssignedListings" &&
                <AssignedListings />
              }
              {
                params.value === "ManagingRequests" &&
                <ManageRequestList />
              }
              {
                params.value === "PropertyOwners" &&
                <PropertyOwnerList />
              }
              {
                params.value === "Chats" &&
                <ChatList />
              }
              {
                params.value === "userProfile" &&
                <UserProfile />
              }
              {
                params.value === "PaymentOptions" &&
                <PaymentCardDetail />
              }
              {params.value === "ManageSubscription" &&
                <ManagerSubscription />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
