import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import url from "../../../utils/services/urls.json"
import { getMethodWithToken } from '../../../utils/services/apis';
import PropertyOwnerAssignList from '../PropertyOwnerAssignList/PropertyOwnerAssignList';
import "./PropertyOwnerList.scss"
import { useNavigate } from 'react-router-dom';
import { navigateLogin } from '../../../utils/services/common';
import DefaultImage from "../../../assets/images/default-image.svg"

const PropertyList = styled.div`
margin-top: 20px;
width:100%;
`

const PropertyOwnerList = () => {
    const navigate = useNavigate()
    const [ownerList, setOwnerList] = useState([])
    const [searchListId, setSearchListId] = useState("")
    const [listDetail, setListDetail] = useState(false)
    const [selectValue, setSelectValue] = useState({})

    useEffect(() => {
        getPropertyOwner()
    }, [])

    const getPropertyOwner = async () => {
        await getMethodWithToken(url.getPropertyOwnerList).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setOwnerList(response.data)
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }
    const handleListdetail = (value) => {
        setSearchListId(value.id)
        setSelectValue(value)
        setListDetail(true)
    }
    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }
    return (
        <PropertyList>
            <div className="property-container">
                <div className="d-flex">
                    <div className="owner-list-card">
                        <div className="owner-main-heading">Property Owners</div>
                        <div className={ownerList?.length > 0 ? "property-owner-card" : "manager-card-without-result"}>
                            {ownerList && ownerList.length > 0 ? ownerList.map((item, index) => {
                                return (
                                    <div className="d-flex" style={{ borderBottom: "0.5px solid #D9D9D9", padding: "15px 0 5px 10px", cursor: "pointer", background: item?.property_owner?.id === searchListId ? "#D9EFFF" : "" }} onClick={() => handleListdetail(item?.property_owner)}>
                                        <div>
                                            <img src={item?.property_owner?.profilePic?.file ? item?.property_owner?.profilePic?.file : DefaultImage} alt="" style={{ width: "60px", height: "70px", borderRadius: "50%" }} />
                                        </div>
                                        <div className="users-heading" >
                                            <div className="user-main-heading" >{item?.property_owner?.name?.length > 0 && getUpparCaseName(item?.property_owner?.name)}</div>
                                            {/* <div className="user-heading">{item?.property_owner?.organization}</div> */}
                                        </div>
                                    </div>
                                )
                            })
                                :
                                <div>No Property Owners List Found</div>
                            }
                        </div>
                    </div>
                    {listDetail &&
                        <PropertyOwnerAssignList propertyDetailId={searchListId} selectValue={selectValue} />
                    }
                </div>
            </div>
        </PropertyList>
    )
}

export default PropertyOwnerList
