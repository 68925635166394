import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMethodWithToken } from '../../../utils/services/apis';
import url from '../../../utils/services/urls.json';
import CardDetail from '../../../Components/commonComponents/CardDeatil/CardDetal/CardDetail';
import NothingDetail from '../../../Components/commonComponents/CardDeatil/NothingDetail/NothingDetail';
import { navigateLogin } from '../../../utils/services/common';


const ManageRequestList = () => {
    const navigate = useNavigate()
    const [manageList, setManageList] = useState([])
    const [sliderImage, setSliderImage] = useState([])

    useEffect(() => {
        handleManagingRequestList()
    }, [])

    const handleManagingRequestList = async () => {
        await getMethodWithToken(url.ManagingRequestList).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setManageList(response?.data)
                if (response?.data?.length > 0) {
                    response?.data.map((value, index) => (
                        setSliderImage(value?.property_images)
                    ))
                }
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }

        })
    }
    const handleManagingRequestDetail = async (id, userName) => {
        navigate(`/Dashboard/Manager/Request/Details/${id}/manager_detail`, {
            state: {
                userName: userName
            }
        })
    }
    const getData = (data) => {
        if (data) {
            handleManagingRequestList()
        }
    }
    return (
        manageList && manageList.length > 0 ?
            <CardDetail ListingDetails={manageList} sliderImage={sliderImage} showButton={true} onClick={handleManagingRequestDetail} acceptValue={getData} />
            :
            <NothingDetail />
    )
}

export default ManageRequestList
