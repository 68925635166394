import React from 'react'
import styled from 'styled-components';
// import AddCard from "../../../assets/images/add-card.svg";
import PaymentPastTransaction from "../PaymentPastTransaction/PaymentPastTransaction"
import "./PaymentCardDetail.scss"

const PaymentCardContainer = styled.div`
font-family: Proxima_nova_reg;
margin-top: 20px;
`

const PaymentCardDetail = () => {
  return (
    <PaymentCardContainer>
      <div className="d-flex">
        <div className="payment-card-seaction">
          <div className="payment-heading">Payment Method</div>
          <hr />
          <div className="payment-card-method">You haven’t saved a method yet</div>
          {/* <div className="payment-save-card">
            <div>
              <img src={AddCard} alt="AddCard" style={{ cursor: "pointer" }} />
            </div>
            <div className="payment-save-card-heading">Save a payment method</div>
          </div> */}
        </div>

        <div>
          <PaymentPastTransaction />
        </div>
      </div>
    </PaymentCardContainer>
  )
}

export default PaymentCardDetail
