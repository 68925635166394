import React from 'react'
import FormControl from '@mui/material/FormControl';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import './FormInput.scss';

const FormInput = (props) => {

  const { value, onChange, style } = props;

  const defaultValue = {
    id: "Text",
    name: 'Text',
    type: "text",
    required: false,
    value: value,
    error: false,
    helperText: 'This is a required field',
    style: style || {},
    onChange: (e) => {
      if (onChange) {
        onChange(e);
      }
    },
  }
  return (
    <>
      <FormControl className='formControl' sx={{ m: 1 }} variant="standard">
        {/* <InputLabel htmlFor={props.id?props.id:defaultValue.id}>{props.name?props.name:defaultValue.name}</InputLabel> */}
        <TextField
          label={props.name ? props.name : defaultValue.name}
          id={props.id ? props.id : defaultValue.id}
          type={props.type ? props.type : ""}
          // required={props.required?props.required:defaultValue.required}
          disabled={props.disabled}
          value={props.value ? props.value : defaultValue.value}
          onChange={props.onChange ? props.onChange : defaultValue.onChange}
          // inputComponent={props.inputComponent}
          error={props.error ? props.error : defaultValue.error}
          helperText={props.error && (props.helperText ? props.helperText : defaultValue.helperText)}
          variant="standard"
          InputProps={{
            style: {
              ...style,
              cursor: "pointer",
            },
            inputComponent: props.inputComponent,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={props.onClick}
                  onMouseDown={props.onMouseDown}
                >
                  {props.showIcon ? props.showIcon : <img src={props.icon} alt="" />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </>
  )
}

export default FormInput
