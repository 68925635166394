import React from 'react'
import successPng from '../../assets/images/success.png'
import GovHome from '../../assets/images/GovHomeLogo.png'
import './SuccessScreen.scss'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import ContinueButton from '../Buttons/ContinueButton/ContinueButton'
const SuccessScreen = (props) => {
    const params = useParams();
    let location = useLocation();
    const navigate = useNavigate();
    return (
        <div className='successScreen d-flex' style={{ height: '100vh' }}>
            <div>
                <img className='GovHome' src={GovHome} alt="" />
            </div>
            <div className="bottomContent text-center m-auto" style={{ maxWidth: '600px', padding: '20px' }}>
                <img src={successPng} alt="" />
                <h3 style={{ fontWeight: 700 }}>{location?.state?.heading}</h3>
                <p style={{ marginTop: "20px", marginBottom: "26px" }}>{location?.state?.title}</p>
                {params.value && params.childValue === 'CreatePassword' ?
                    <div >
                        <ContinueButton className={true ? "continue" : "continueDisabled"} disabled={!true} text={'Get Started'} height={'50px'} color={'#fff'} onClick={() => navigate(`/${params.value}/Login`)} />
                    </div>
                    :
                    ""
                }
                {/* {params.value && params.childValue === 'ForgotPassword' ?
                    <div >
                        <ContinueButton className={true ? "continue" : "continueDisabled"} disabled={!true} text={'Done'} height={'50px'} color={'#fff'} onClick={() => navigate(`/ResetPassword`)} />
                    </div>
                    :
                    ""
                } */}
                {params.value && params.childValue === 'ResetPassword' ? <div >
                    <ContinueButton className={true ? "continue" : "continueDisabled"} disabled={!true} text={'Continue to Login'} height={'50px'} color={'#fff'} onClick={() => navigate(`/${params.value}/Login`)} />
                </div>
                    :
                    ""

                }
                {/* {params.value && params.childValue === 'Registration' ?
                    <div >
                        <ContinueButton className={true ? "continue" : "continueDisabled"} disabled={!true} text={'Done'} height={'50px'} color={'#fff'} onClick={() => navigate(`/CreatePassword`)} />
                    </div>
                    :
                    ""
                } */}
            </div>
        </div>
    )
}
export default SuccessScreen;