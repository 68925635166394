import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { getMethodWithToken, postMethod, postMethodWithToken } from '../../../utils/services/apis';
import url from "../../../utils/services/urls.json"
import DefaultImage from "../../../assets/images/default-image.svg"
import { getParam, getToken, navigateLogin, setUserId } from "../../../utils/services/common";
import Modal from "react-modal"
import "./Navbar.scss"
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import { TextField } from '@mui/material';
import ContinueButton from "../../Buttons/ContinueButton/ContinueButton"

const StyledNavBar = styled.div`
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    top:0;
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    Z-index:2;
`;
const UlofNav = styled.ul`
font-family: Proxima_nova_medium;
font-size:'16px';
font-weight:600;
list-style: none;
padding: 0;
margin: 0;
display: flex;
cursor: pointer;
`;
const StyledLi = styled.li`
   margin:0 20px 0 20px;

   @media only screen and (max-width: 1110px) {
    margin:0 10px 0 10px;
  }
`;

const Navbar = (props) => {
    const navigate = useNavigate()
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const styleWithRelist = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "30vw" : "28vw",
            height: screenSize.width <= 800 ? "45vh" : "60vh",
            padding: "0px",
        },
    }

    const [profileName, setProfileName] = useState("")
    const [profileImage, setProfileImage] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [continueButton, setcontinueButton] = useState(false);
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [message, setMessage] = useState("")

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value.trimStart())
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value.trimStart())
    }
    const handleEmailChange = (event) => {
        setEmailAddress(event.target.value.trimStart())
    }
    const handleMessageChange = (event) => {
        setMessage(event.target.value.trimStart())
    }

    const handleOpenModal = () => {
        setOpenModal(true)
        setFirstName("")
        setLastName("")
        setEmailAddress("")
        setMessage("")
    }
    const closeModal = () => {
        setOpenModal(false)
    }

    useEffect(() => {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)) {
            setcontinueButton(true)
        } else {
            setcontinueButton(false)
        }
    }, [emailAddress])

    useEffect(() => {
        getProfile()
    }, [])

    const getProfile = async () => {
        await getMethodWithToken(url.userProfile).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setProfileName(response.data.name && response.data.name)
                setProfileImage(response?.data?.profilePic)
                setUserId(response?.data?.id)
            }
            else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }

    const handlePostContact = async () => {
        let body = {
            "first_name": firstName,
            "last_name": lastName,
            "email": emailAddress,
            "message": message
        }
        if (firstName !== "" && lastName !== "" && emailAddress !== "" && message !== "") {
            if (continueButton) {
                await postMethod(url.postContact, body).then((response) => {
                    if (response?.status === 200 || response?.status === 201) {
                        toast.success(response?.data?.success)
                        setOpenModal(false)
                    }
                })
            }
        } else {
            toast.error("Please Fill All Field")

        }
    }

    const handleProfileChange = () => {
        navigate("/Dashboard/userProfile")
    }

    const handleLogout = async () => {
        const paramValue = getParam();

        await postMethodWithToken(url.logout, getToken()).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/${paramValue}/Login`, {});
                localStorage.removeItem("token")
                localStorage.removeItem("userId")
                localStorage.removeItem("subscriptionDetail")
                localStorage.removeItem("role-manager")
            }
        })
    }
    const handleChat = () => {
        navigate(`/Dashboard/Chats`)
    }
    return (
        <>
            <StyledNavBar >
                <div className='navbar d-flex justify-content-between w-100' style={{ margin: '0 40px 0 40px' }}>
                    <div ><img src={require('../../../assets/images/GovHomeLogo.png')} alt="" style={{ maxWidth: '40px' }} /></div>
                    <div>
                        <UlofNav>
                            <StyledLi onClick={() => handleOpenModal()}>Contact Us</StyledLi>
                            <StyledLi>About Us</StyledLi>
                        </UlofNav>
                    </div>
                    <div className='d-flex align-items-center'>
                        <MessageOutlinedIcon style={{ marginRight: '30px', cursor: "pointer" }} onClick={() => handleChat()} />
                        {/* <NotificationsOutlinedIcon style={{ marginRight: '30px' }} /> */}
                        <div className='d-flex'>
                            <img src={profileImage !== null ? profileImage?.file : DefaultImage} alt="" style={{ maxWidth: '40px', maxHeight: '40px', borderRadius: '50%', cursor: "pointer" }} onClick={() => handleProfileChange()} />
                            <div className="dropdown-center align-items-center d-flex">
                                <button className="btn dropdown-toggle border-0 p-0 ms-2" style={{ color: '#0075CA' }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {profileName}
                                </button>
                                <ul className="dropdown-menu" style={{ left: '-51px' }}>
                                    {/* <li><button className="dropdown-item">Action</button></li>
                                <li><button className="dropdown-item">Another action</button></li> */}
                                    <li><button className="dropdown-item" onClick={handleLogout}>Logout</button></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </StyledNavBar>
            <Modal isOpen={openModal} style={styleWithRelist} overlayClassName="myoverlay">
                <div className="contact-modal">
                    <div className="contact-heading">Contact Detail</div>
                    <Row style={{ padding: "15px 20px 0 20px" }}>
                        <Col lg={6}>
                            <div className="message-heading">First Name</div>
                            <TextField
                                fullWidth
                                required
                                value={firstName}
                                onChange={(e) => handleFirstNameChange(e)}
                                id="outlined-required"
                            />

                        </Col>
                        <Col lg={6}>
                            <div className="message-heading">Last Name</div>
                            <TextField
                                fullWidth
                                required
                                value={lastName}
                                onChange={(e) => handleLastNameChange(e)}
                                id="outlined-required"
                            />
                        </Col>
                        <Col lg={12}>
                            <div className="message-heading" style={{ marginTop: "20px" }}>Email Address</div>
                            <TextField
                                fullWidth
                                required
                                value={emailAddress}
                                onChange={(e) => handleEmailChange(e)}
                                id="outlined-required"
                            />
                            {(!continueButton && emailAddress !== "") &&
                                <div className="email-error">Please Enter a Vaild Email</div>
                            }
                        </Col>
                        <Col lg={12}>
                            <div className="message-heading" style={{ marginTop: "15px" }}>Message</div>
                            <textarea name="postContent" style={{ width: '100%' }} value={message} onChange={(e) => handleMessageChange(e)} rows="5" />
                        </Col>
                        <Col lg={6} style={{ marginTop: "15px" }}>
                            <ContinueButton text={'Cancel'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontWeight={500} width={"100%"} onClick={() => closeModal()} />
                        </Col>
                        <Col lg={6} style={{ marginTop: "15px" }}>
                            <ContinueButton text={'Save'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'100%'} borderRadius={"4px"} fontWeight={600} onClick={() => handlePostContact()} />
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}

export default Navbar
