import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from "../../../Components/commonComponents/navbar/Navbar";
import Sidebar from "../../../Components/commonComponents/sidebar/Sidebar";
import ContinueButton from "../../../Components/Buttons/ContinueButton/ContinueButton";
import { Col, Row } from 'react-bootstrap';
import url from "../../../utils/services/urls.json"
import "./ChangePlan.scss"
import { getMethodWithToken, postMethodWithToken } from '../../../utils/services/apis';
import { getUserId, navigateLogin } from '../../../utils/services/common';

const ChangePlan = () => {
    const navigate = useNavigate();
    const moment = require('moment-timezone');
    const [subscriptionList, setSubscriptionList] = useState([])
    const [selectedPlanId, setSelectedPlanId] = useState("")
    const [subscriptionPrice, setSubscriptionPrice] = useState("")
    const [monthlyPlan, setMonthlyPlan] = useState(false)

    const subscriptionDetail = localStorage.getItem("subscriptionDetail") !== undefined ? JSON?.parse(localStorage.getItem("subscriptionDetail")) : "";

    useEffect(() => {
        getSubscriptionList()
    }, [])
    const getSubscriptionList = async () => {
        await getMethodWithToken(url.getSubscriptionManager).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setSubscriptionList(response?.data)
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }
    const getPlanNameUpparCase = (planName) => {
        const words = planName.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }
    const handleSubscriptionButton = (e) => {
        setSelectedPlanId(e.id)
        localStorage.setItem("subscriptionPrice", e?.pricePerListing?.slice(1))
        setSubscriptionPrice(e?.pricePerListing?.slice(1))
        if (e?.name === "Monthly") {
            setMonthlyPlan(true)
        } else {
            setMonthlyPlan(false)
        }
    }
    const handleChangeSubscription = async () => {
        let body = {
            "user_id": getUserId(),
            "masterSubscrption": selectedPlanId,
            "price": subscriptionPrice,
            "per_month": monthlyPlan
        }
        await postMethodWithToken(url.postSubscription, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/Dashboard/Checkout/Payment`)
            }
        })
    }
    return (
        <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
            <Navbar />
            <div className="d-flex">
                <div className="Sidebar_control" style={{ overflow: "scroll", width: "37%", height: "100vh" }} >
                    <Sidebar />
                </div>
                <div className="Sidebar_control" style={{ overflow: "scroll", width: "100%", height: "100vh" }}>
                    <div style={{ display: 'inline-block', marginTop: '60px', marginBottom: '5.5vh' }}>
                        <div className="subscription-package-container">
                            <div className="current-heading">Current Plan</div>
                            <hr />
                            <div className="monthly-heading">{subscriptionDetail?.per_month === true ? "Monthly Plan" : "Yearly Plan"}</div>
                            <div className="d-flex mt-3" >
                                <div className="price-manager-heading" >${subscriptionDetail?.price}</div>
                                <div className="month-manager-heading">/ {subscriptionDetail?.per_month === true ? "month" : "year"}</div>
                            </div>
                            <Row className="mt-3">
                                <Col lg={3}>
                                    <div className="payment-description">Active since</div>
                                    <div className="date-description">{moment.tz(subscriptionDetail?.propertyAvailfrom, subscriptionDetail?.propertyAvailfrom).format("DD MMMM YYYY")}</div>
                                </Col>
                                <Col lg={3}>
                                    <div className="payment-description">End Date</div>
                                    <div className="date-description">{moment.tz(subscriptionDetail?.end_date, subscriptionDetail?.end_date).format("DD MMMM YYYY")}</div>
                                </Col>
                            </Row>
                            <div className="d-flex justify-content-center change-plan-heading">Change Plan</div>
                            <div className="d-flex justify-content-center change-plan-description">Select a Subscription Package</div>
                            <div className='d-flex Manager-Button'>
                                {subscriptionList && subscriptionList.length > 0 && subscriptionList.map((data, index) => {
                                    return (
                                        <div className="Manager-SubscriptionButton" style={{ border: (data?.id === selectedPlanId && data.price !== subscriptionDetail?.price) ? "2px solid #0075CA" : (data?.name === "Monthly " ? "2px solid #505050" : "2px solid #E3A301") }} onClick={() => (data.price !== subscriptionDetail?.price && handleSubscriptionButton(data))}>
                                            <div className="d-flex justify-content-center subscription-button" style={{ color: data?.name === "Monthly" ? "#505050" : "#E3A301" }}  >{data?.name?.length > 0 && getPlanNameUpparCase(data?.name)}
                                            </div>
                                            <div className="d-flex justify-content-center subscription-price" style={{ color: data?.name === "Monthly" ? "#0075CA" : "#E3A301" }}>{data?.pricePerListing}</div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="d-flex justify-content-end checkout-btn">
                                <ContinueButton text="Continue to Checkout" onClick={() => handleChangeSubscription()} color={'#fff'} backgroundColor={(selectedPlanId === "" || selectedPlanId === subscriptionDetail?.masterSubscrption) ? 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)' : 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} disabled={(selectedPlanId === "" || selectedPlanId === subscriptionDetail?.masterSubscrption)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePlan
