import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import url from "../../../utils/services/urls.json"
import { getMethodWithToken, postMethodWithToken } from '../../../utils/services/apis';
import { getUserId, getManagerRole, navigateLogin } from '../../../utils/services/common';
import ContinueButton from '../../../Components/Buttons/ContinueButton/ContinueButton';
import AssignedListingsDetail from '../AssignedListingsDetails/AssignedListingsDetails';
import '../AssignedListings/AssignedListings.scss'

const AssignedListings = () => {
    const navigate = useNavigate()
    const [subscriptionList, setSubscriptionList] = useState([])
    const [subscriptionData, setSubscriptionData] = useState([])
    const [flag, setFlag] = useState(false)
    const [selectedPlanId, setSelectedPlanId] = useState("")
    const [subscriptionPrice, setSubscriptionPrice] = useState("")
    const [monthlyPlan, setMonthlyPlan] = useState(false)
    const roleManager = JSON.parse(getManagerRole())

    useEffect(() => {
        getSubscriptionDetail()
    }, [])

    const getSubscriptionDetail = async () => {
        await getMethodWithToken(url.getSubscriptionDetail).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setSubscriptionData(response?.data)
                if (response?.data?.length === 0 && roleManager === false) {
                    setFlag(true)
                }
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }

    useEffect(() => {
        if (flag) {
            getSubscriptionList()
        }
    }, [flag])

    const getSubscriptionList = async () => {
        await getMethodWithToken(url.getSubscriptionManager).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                let changeSubscriptionList = response?.data.slice(0).reverse().map((val) => {
                    return val;
                })
                setSubscriptionList(changeSubscriptionList)
            } 
            else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)}
        })
    }

    const handleSubscriptionButton = (e) => {
        setSelectedPlanId(e.id)
        localStorage.setItem("subscriptionPrice", e?.pricePerListing?.slice(1))
        setSubscriptionPrice(e?.pricePerListing?.slice(1))
        if (e?.name === "Monthly") {
            setMonthlyPlan(true)
        } else {
            setMonthlyPlan(false)
        }
        // let arr = [...array]

        // if (arr.includes(e.id)) {
        //     let index = arr.findIndex(item => item === e.id)
        //     arr.splice(index, 1)
        //     setArray(arr)

        // } else {
        //     arr.push(e.id)
        //     setArray(arr)
        // }
    }

    const getPlanNameUpparCase = (planName) => {
        const words = planName.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }
    const handleAddSubscription = async () => {
        let body = {
            "user_id": getUserId(),
            "masterSubscrption": selectedPlanId,
            "price": subscriptionPrice,
            "per_month": monthlyPlan
        }
        await postMethodWithToken(url.postSubscription, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/Dashboard/Checkout/Payment/assignList`)
            }
        })
    }
    return (
        ((subscriptionData && subscriptionData.length > 0) || roleManager === true) ?
            <AssignedListingsDetail />
            :
            <div className="subscription-package-container">
                <div class="d-flex justify-content-center active-heading">No Active Plan</div>
                <div class="d-flex justify-content-center active-description">Subscribe to start managing properties</div>
                <div className="d-flex justify-content-center subscription-package">Select a Subscription Package</div>
                <div className="d-flex Manager-Button">
                    {subscriptionList && subscriptionList.length > 0 && subscriptionList.map((data, index) => {
                        return (
                            <div className="Manager-SubscriptionButton" style={{ border: data?.id === selectedPlanId ? "2px solid #0075CA" : (data?.name === "Monthly" ? "2px solid #505050" : "2px solid #E3A301") }} onClick={() => handleSubscriptionButton(data)}>
                                <div className="d-flex justify-content-center subscription-button" style={{ color: data?.name === "Monthly" ? "#505050" : "#E3A301" }}  >{data?.name?.length > 0 && getPlanNameUpparCase(data?.name)}
                                </div>
                                <div className="d-flex justify-content-center subscription-price" style={{ color: data?.name === "Monthly" ? "#0075CA" : '#E3A301' }}>{data?.pricePerListing}</div>
                            </div>
                        )
                    })}
                </div>
                <div className="d-flex justify-content-center Manager-freeTrial">Get 14 days free once you add your card (only valid once)</div>
                <div className="d-flex justify-content-end checkout-btn">
                    <ContinueButton text="Continue to Checkout" onClick={() => handleAddSubscription()} color={'#fff'} backgroundColor={selectedPlanId !== "" ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={selectedPlanId === ""} />
                </div>
            </div>

    )
}
export default AssignedListings
