import React from 'react';
import "./NothingDetail.scss"

const NothingDetail = () => {
    return (
        <div className="empty-details">
            <div className="empty-heading" >Nothing here yet <br /><span className="empty-description">Wait for a request</span></div>
        </div>
    )
}

export default NothingDetail
