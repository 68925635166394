export const setToken = (token) => {
    localStorage.setItem("token", token);
};

export const getToken = () => {
    return localStorage.getItem("token") || null;
};

export const setParam = (param) => {
    localStorage.setItem("param", param);
};
export const getParam = () => {
    return localStorage.getItem("param") || null;
};
export const setUserId = (userId) => {
    localStorage.setItem("userId", userId)
}
export const getUserId = () => {
    return localStorage.getItem("userId") || null;
}
export const setManagerRole = (managerRole) => {
    localStorage.setItem("role-manager", managerRole)
}
export const getManagerRole = () => {
    return localStorage.getItem("role-manager") || null;
}
export const navigateLogin = () => {
    return localStorage.removeItem("token") || null;
};
