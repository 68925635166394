import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Reply from "../../../../assets/images/reply.png";
import Bedroom from "../../../../assets/images/favoriteProperty/bedroom.svg"
import Bathroom from "../../../../assets/images/favoriteProperty/bathroom.svg"
import Parking from "../../../../assets/images/favoriteProperty/parking.svg"
import Clock from "../../../../assets/images/favoriteProperty/clock.svg"
import Home from "../../../../assets/images/favoriteProperty/home.svg"
import Furnishing from "../../../../assets/images/favoriteProperty/furnishing.svg"
import ArrowForward from "../../../../assets/images/listingManager/Arrow-property.svg"
import "./DetailWithOwnerList.scss"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const DetailOwnerContainer = styled.div`
font-family: Proxima_nova_reg;
width:800px;
height: auto;
background: white;
box-shadow: 0px 2px 8px 0px #0000001F;
`;

const useStyles = makeStyles((theme) => ({
    rootValue: {
        maxWidth: 400,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 210,
        display: 'block',
        maxWidth: 300,
        overflow: 'hidden',
        width: '100%',
    },
}));

const DetailWithOwnerList = (props) => {
    const classes = useStyles();
    const moment = require('moment-timezone');
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = props.sliderImage.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }


    return (
        props.ListingDetailsWithOwner && props.ListingDetailsWithOwner.length > 0 && props.ListingDetailsWithOwner.map((item, index) => {
            return (
                <DetailOwnerContainer>
                    <div className="assign-card">
                        <Row>
                            <Col lg={4}>
                                <div className={classes.rootValue}>
                                    <AutoPlaySwipeableViews
                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                        index={activeStep}
                                        onChangeIndex={handleStepChange}
                                        enableMouseEvents
                                    >
                                        {item.property_images && item.property_images.length > 0 && item.property_images.map((step, index) => {
                                            return (
                                                <>
                                                    <div>
                                                        <img className={classes.img} src={step?.media?.file} alt={step.label} />
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </AutoPlaySwipeableViews>
                                    <MobileStepper
                                        position="static"
                                        variant="text"
                                        nextButton={
                                            <Button size="small" style={{ color: "white" }} onClick={handleNext} disabled={activeStep === maxSteps - 1}>

                                                {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                            </Button>
                                        }
                                        backButton={
                                            <Button size="small" style={{ color: "white" }} onClick={handleBack} disabled={activeStep === 0}>
                                                {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                            </Button>
                                        }
                                    />
                                </div>
                                <div class="d-flex justify-content-center" style={{ paddingTop: "35%" }}>
                                    <div>
                                        <div className="rental-price">${item?.subscription_details?.price}<span style={{ fontSize: "16px", color: "#505050" }} >/month</span></div>
                                        <div className="rent-heading" >Rent</div>
                                    </div>
                                    <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                                    <div>
                                        <div className="deposit-price" style={{ marginLeft: "10px" }}>${item?.subscription_details?.deposit_amount}<span></span></div>
                                        <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="property-detail-conatiner">
                                    <div className="d-flex justify-content-between" style={{ paddingTop: "10px" }}>
                                        <div className="d-flex">
                                            <div>
                                                <img src={item?.Property_owner?.[0]?.profilePic} alt="" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                                            </div>
                                            <div>
                                                <div className="d-flex" style={{ paddingLeft: "7px" }}>
                                                    <div className="user-heading">{item?.Property_owner?.[0]?.name?.length > 0 && getUpparCaseName(item?.Property_owner?.[0]?.name)}</div>
                                                    <div className="user-dot">.</div>
                                                    <div className="property-owner">Property Owner</div>
                                                </div>
                                                {/* <div style={{ color: "#707070", paddingLeft: "7px", fontSize: "16px", textAlign: "start" }}>{item?.Property_owner?.[0]?.orgnization}</div> */}
                                            </div>
                                        </div>
                                        {/* <div className="request-profile">1</div> */}
                                    </div>
                                    {/* <div className="d-flex" style={{ marginTop: "10px" }} >
                                        <div style={{ fontSize: "17px" }}><span style={{ fontWeight: 600 }}>{item?.bedroomCount} Bedroom Flat</span> in <span style={{ fontWeight: 600 }}> </span>  <span style={{ fontWeight: 600 }}>{item?.location?.locality}</span></div>
                                        <div style={{ marginLeft: "10px" }}><img src={Reply} alt="" /></div>
                                    </div> */}
                                    <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}> {item?.location?.locality}</div>
                                    <div className='d-flex' style={{ marginTop: "10px", borderBottom: "1px solid #B7B7B7", paddingBottom: "5px" }}>
                                        <div className='d-flex'>
                                            <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                            <div className="categroy-heading">Bedroom</div>
                                            <div className="category-value">{item?.bedroomCount}</div>
                                        </div>
                                        <div className='d-flex'>
                                            <div style={{ marginLeft: "20px" }} ><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                            <div className="categroy-heading">Bathroom</div>
                                            <div className="category-value">{item?.propertyType?.name === "Room" ? item?.room_sharing?.name : item?.bathroomCount}</div>
                                        </div>
                                        <div className='d-flex'>
                                            <div style={{ marginLeft: "20px" }}><img src={Parking} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                            <div className="categroy-heading">Parking</div>
                                            <div className="category-value">{item?.parkingType?.name?.length && getUpparCaseName(item?.parkingType?.name)}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <Row >
                                            <Col lg={3} style={{ borderRight: "1px solid #B7B7B7", height: "100px" }}>
                                                <div className="d-flex" style={{ marginTop: "10px" }} >
                                                    <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                    <div className="categroy-heading">Availability</div>
                                                </div>
                                                <div className="select-value-heading">{moment?.tz(item?.subscription_details?.propertyAvailfrom?.[0], item?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                            </Col>
                                            <Col lg={4} style={{ borderRight: "1px solid #B7B7B7", height: "100px", marginLeft: "10px" }}>
                                                <div className="d-flex" style={{ marginTop: "10px" }} >
                                                    <div><img src={Home} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                    <div className="categroy-heading">Property Type</div>
                                                </div>
                                                <div className="select-value-heading">{item?.propertyType?.name}</div>
                                            </Col>
                                            <Col lg={4} style={{ marginLeft: "10px" }}>
                                                <div className="d-flex" style={{ marginTop: "10px" }} >
                                                    <div><img src={Furnishing} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                    <div className="categroy-heading">Furnishing</div>
                                                </div>
                                                <div className="select-value-heading">{item?.furnishType?.name?.length && getUpparCaseName(item?.furnishType?.name)}</div>
                                            </Col>
                                        </Row>
                                        <div className="d-flex justify-content-end" style={{ marginRight: "40px", paddingBottom: "20px" }} >
                                            <div className="view-heading" onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}>View Details</div>
                                            <div style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </DetailOwnerContainer>

            )
        })
    )
}

export default DetailWithOwnerList
