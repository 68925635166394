import React, { useEffect, useState } from 'react'
import { getMethodWithToken } from '../../../utils/services/apis';
import url from "../../../utils/services/urls.json"
import { useNavigate } from 'react-router-dom';
import CardDetail from '../../../Components/commonComponents/CardDeatil/CardDetal/CardDetail';
import NothingDetail from '../../../Components/commonComponents/CardDeatil/NothingDetail/NothingDetail';
import { InputAdornment, } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Select } from '@mui/material'
import './AssignedListingsDetails.scss'
import { navigateLogin } from '../../../utils/services/common';


const AssignDetailContainer = styled.div`
width:900px;
height: auto;
`

const AssignedListingsDetail = () => {
    // const navigate = useNavigate()
    // const [assignedListDetail, setAssignedListDetail] = useState([])
    // const [sliderImage, setSliderImage] = useState([])

    // useEffect(() => {
    //     getAssignListDetail()
    // }, [])

    // const getAssignListDetail = async () => {
    //     await getMethodWithToken(url.getAssignList).then((response) => {
    //         if (response.status === 200 || response.status === 201) {
    //             setAssignedListDetail(response?.data)
    //             if (response?.data?.length > 0) {
    //                 response?.data.map((value, index) => (
    //                     setSliderImage(value?.property_images)
    //                 ))
    //             }
    //         }
    //     })
    // }
    // const handleViewDetail = (id, userName, availableDate) => {
    //     navigate(`Assign/Detail/${id}`, {
    //         state: {
    //             userName: userName,
    //             availableDate: availableDate
    //         }
    //     })
    // }
    const navigate = useNavigate()
    const [sliderImage, setSliderImage] = useState([])
    const [assignedListDetail, setAssignedListDetail] = useState([])
    const [searchInput, setSearchInput] = useState("")
    const [sortValue, setSortValue] = useState("")

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value)
    }
    const handleSortChange = (event) => {
        setSortValue(event.target.value)
    }
    useEffect(() => {
        getAssignListDetail()
    }, [searchInput])
    const getAssignListDetail = async () => {
        await getMethodWithToken(url.getAssignList + "?search=" + searchInput).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setAssignedListDetail(response?.data)
                if (response?.data?.length > 0) {
                    response?.data.map((value, index) => (
                        setSliderImage(value?.property_images)
                    ))
                }
            }
            else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }
    const handleViewDetail = (id) => {
        navigate(`Assign/Detail/${id}`)
    }


    return (
        <div className="assign-list-container" >
            <AssignDetailContainer>
                <div className="d-flex justify-content-between">
                    <TextField
                        name="search"
                        id="outlined-basic"
                        label="Search by name, owner or location..."
                        variant="outlined"
                        value={searchInput}
                        onChange={(e) => handleSearchInputChange(e)}
                        style={{ width: "508px", background: "#ffffff" }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <div className="search-btn" style={{ margin: "5px 4px 5px 0" }}>
                                            <div>
                                                <SearchIcon style={{ color: "white", width: "25px", height: "25px" }} />
                                            </div>
                                            <div style={{ color: "white", fontSize: "20px", fontWeight: 500, paddingLeft: "7px" }} >Search</div>
                                        </div>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <div className="d-flex justify-content-end">
                        <div className="sort-heading mt-3">Sort by:</div>
                        <div>
                            <FormControl sx={{ minWidth: 180, height: "42px", border: "1px soild white" }}>
                                <Select
                                    value={sortValue}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={(e) => handleSortChange(e)}
                                    sx={{
                                        boxShadow: "none",
                                        marginLeft: "10px",
                                        background: "white",
                                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                            border: 0,
                                        },
                                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                            border: 0,
                                        },
                                    }}

                                >
                                    <MenuItem value="" className="filter_items">Posted on Newest First</MenuItem>
                                    <MenuItem value={10} className="filter_items">Ten</MenuItem>
                                    <MenuItem value={20} className="filter_items">Twenty</MenuItem>
                                    <MenuItem value={30} className="filter_items">Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                {assignedListDetail && assignedListDetail.length > 0 ?
                    <div>
                        <CardDetail ListingDetails={assignedListDetail} sliderImage={sliderImage} showRequest={true} onClick={handleViewDetail} />
                    </div>
                    :
                    <NothingDetail />
                }
            </AssignDetailContainer>
        </div>


    )
}
export default AssignedListingsDetail