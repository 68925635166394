import React from 'react'
import styled from 'styled-components';
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { useNavigate, useLocation } from 'react-router-dom';
import "./Sidebar.scss";

const StyledSidebar = styled.div`
background: #ffffff;
// height:85%;
top:0;
z-index:-1;
padding:20px 20px 50px 40px;
margin: 80px 20px 30px 40px;
&::-webkit-scrollbar {
  display: none;
};
@media only screen and (min-width: 1311px) and  (max-width: 1481px) {
  margin:80px 15px 40px 30px;
  padding:10px 20px 55px 20px;
}
@media only screen and (min-width: 1114px) and  (max-width: 1312px) {
  // margin:80px 10px 40px 20px;
  padding:5px 5px 60px 5px;
}
@media only screen and (max-width: 1113px) {
  // margin:80px 10px 40px 20px;
  padding:5px 0 70px 0;
}
`;


const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <StyledSidebar>
      <Navigation
        activeItemId={location.pathname === "/Dashboard/AssignedListings/AssignedListingsDetails" ? "/Dashboard/AssignedListings" : location.pathname === "/Dashboard/Manage/Change/Plan" ? "/Dashboard/ManageSubscription" : location.pathname}
        onSelect={({ itemId }) => {
          localStorage.removeItem("PageNo");
          navigate(itemId);

        }}
        items={[
          {
            title: "Assigned Listings",
            itemId: "/Dashboard/AssignedListings",
            elemBefore: () => (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.66667 1.33268H24V3.99935H6.66667V1.33268ZM0 0.666016H4V4.66602H0V0.666016ZM0 9.99935H4V13.9993H0V9.99935ZM0 19.3327H4V23.3327H0V19.3327ZM6.66667 10.666H24V13.3327H6.66667V10.666ZM6.66667 19.9993H24V22.666H6.66667V19.9993Z" fill={(location.pathname === "/Dashboard/AssignedListings" || location.pathname === "/Dashboard/AssignedListings/AssignedListingsDetails") ? "#2975CA" : "#919CA5"} />
              </svg>

            ),
          },
          {
            title: "Managing Requests",
            itemId: "/Dashboard/ManagingRequests",
            elemBefore: () => (
              <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.6666 18.0033V20.7892C12.8325 20.4944 11.935 20.334 10.9999 20.334C6.58164 20.334 2.99992 23.9157 2.99992 28.334H0.333252C0.333252 22.4429 5.10888 17.6673 10.9999 17.6673C11.9207 17.6673 12.8143 17.784 13.6666 18.0033ZM10.9999 16.334C6.57992 16.334 2.99992 12.754 2.99992 8.33398C2.99992 3.91398 6.57992 0.333984 10.9999 0.333984C15.4199 0.333984 18.9999 3.91398 18.9999 8.33398C18.9999 12.754 15.4199 16.334 10.9999 16.334ZM10.9999 13.6673C13.9466 13.6673 16.3333 11.2807 16.3333 8.33398C16.3333 5.38732 13.9466 3.00065 10.9999 3.00065C8.05325 3.00065 5.66659 5.38732 5.66659 8.33398C5.66659 11.2807 8.05325 13.6673 10.9999 13.6673ZM18.9999 21.6673V17.6673H21.6666V21.6673H25.6666V24.334H21.6666V28.334H18.9999V24.334H14.9999V21.6673H18.9999Z" fill={location.pathname === "/Dashboard/ManagingRequests" ? "#2975CA" : "#919CA5"} />
              </svg>
            ),
          },
          {
            title: "Property Owners",
            itemId: "/Dashboard/PropertyOwners",
            elemBefore: () => (
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30" fill="none">
                <path d="M1.04409 2.76789L12 0.333252L22.9559 2.76789C23.566 2.90347 24 3.44455 24 4.06948V17.3851C24 20.0599 22.6632 22.5578 20.4376 24.0415L12 29.6666L3.5624 24.0415C1.33681 22.5578 0 20.0599 0 17.3851V4.06948C0 3.44455 0.43404 2.90347 1.04409 2.76789ZM2.66667 5.13904V17.3851C2.66667 19.1683 3.55787 20.8335 5.0416 21.8227L12 26.4617L18.9584 21.8227C20.4421 20.8335 21.3333 19.1683 21.3333 17.3851V5.13904L12 3.06497L2.66667 5.13904ZM12 13.6666C10.1591 13.6666 8.66667 12.1742 8.66667 10.3333C8.66667 8.49231 10.1591 6.99992 12 6.99992C13.8409 6.99992 15.3333 8.49231 15.3333 10.3333C15.3333 12.1742 13.8409 13.6666 12 13.6666ZM6.03661 20.3333C6.36825 17.3333 8.91163 14.9999 12 14.9999C15.0884 14.9999 17.6317 17.3333 17.9633 20.3333H6.03661Z" fill={location.pathname === "/Dashboard/PropertyOwners" ? "#2975CA" : "#919CA5"} />
              </svg>
            ),
          },
          {
            title: "Chats",
            itemId: "/Dashboard/Chats",
            elemBefore: () => (
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.66675 12.666C9.04453 12.666 9.36119 12.5382 9.61675 12.2827C9.8723 12.0271 10.0001 11.7105 10.0001 11.3327C10.0001 10.9549 9.8723 10.6382 9.61675 10.3827C9.36119 10.1271 9.04453 9.99935 8.66675 9.99935C8.28897 9.99935 7.9723 10.1271 7.71675 10.3827C7.46119 10.6382 7.33342 10.9549 7.33342 11.3327C7.33342 11.7105 7.46119 12.0271 7.71675 12.2827C7.9723 12.5382 8.28897 12.666 8.66675 12.666ZM14.0001 12.666C14.3779 12.666 14.6945 12.5382 14.9501 12.2827C15.2056 12.0271 15.3334 11.7105 15.3334 11.3327C15.3334 10.9549 15.2056 10.6382 14.9501 10.3827C14.6945 10.1271 14.3779 9.99935 14.0001 9.99935C13.6223 9.99935 13.3056 10.1271 13.0501 10.3827C12.7945 10.6382 12.6667 10.9549 12.6667 11.3327C12.6667 11.7105 12.7945 12.0271 13.0501 12.2827C13.3056 12.5382 13.6223 12.666 14.0001 12.666ZM19.3334 12.666C19.7112 12.666 20.0279 12.5382 20.2834 12.2827C20.539 12.0271 20.6667 11.7105 20.6667 11.3327C20.6667 10.9549 20.539 10.6382 20.2834 10.3827C20.0279 10.1271 19.7112 9.99935 19.3334 9.99935C18.9556 9.99935 18.639 10.1271 18.3834 10.3827C18.1279 10.6382 18.0001 10.9549 18.0001 11.3327C18.0001 11.7105 18.1279 12.0271 18.3834 12.2827C18.639 12.5382 18.9556 12.666 19.3334 12.666ZM0.666748 27.3327V3.33268C0.666748 2.59935 0.927859 1.97157 1.45008 1.44935C1.9723 0.927127 2.60008 0.666016 3.33341 0.666016H24.6667C25.4001 0.666016 26.0279 0.927127 26.5501 1.44935C27.0723 1.97157 27.3334 2.59935 27.3334 3.33268V19.3327C27.3334 20.066 27.0723 20.6938 26.5501 21.216C26.0279 21.7382 25.4001 21.9993 24.6667 21.9993H6.00008L0.666748 27.3327ZM4.86675 19.3327H24.6667V3.33268H3.33341V20.8327L4.86675 19.3327Z" fill={location.pathname === "/Dashboard/Chats" ? "#2975CA" : "#919CA5"} />
              </svg>

            )
          },
          {
            title: "Profile",
            itemId: "/Dashboard/userProfile",
            elemBefore: () => (
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.33325 29.334C5.33325 23.4429 10.1089 18.6673 15.9999 18.6673C21.891 18.6673 26.6666 23.4429 26.6666 29.334H23.9999C23.9999 24.9157 20.4182 21.334 15.9999 21.334C11.5816 21.334 7.99992 24.9157 7.99992 29.334H5.33325ZM15.9999 17.334C11.5799 17.334 7.99992 13.754 7.99992 9.33398C7.99992 4.91398 11.5799 1.33398 15.9999 1.33398C20.4199 1.33398 23.9999 4.91398 23.9999 9.33398C23.9999 13.754 20.4199 17.334 15.9999 17.334ZM15.9999 14.6673C18.9466 14.6673 21.3333 12.2807 21.3333 9.33398C21.3333 6.38732 18.9466 4.00065 15.9999 4.00065C13.0533 4.00065 10.6666 6.38732 10.6666 9.33398C10.6666 12.2807 13.0533 14.6673 15.9999 14.6673Z" fill={location.pathname === "/Dashboard/userProfile" ? "#2975CA" : "#919CA5"} />
              </svg>
            )
          },
          {
            title: "Payment Options",
            itemId: "/Dashboard/PaymentOptions",
            elemBefore: () => (
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.00643 4H28.0065C28.7429 4 29.3398 4.59696 29.3398 5.33333V26.6667C29.3398 27.4031 28.7429 28 28.0065 28H4.00643C3.27006 28 2.6731 27.4031 2.6731 26.6667V5.33333C2.6731 4.59696 3.27006 4 4.00643 4ZM26.6731 14.6667H5.33976V25.3333H26.6731V14.6667ZM26.6731 12V6.66667H5.33976V12H26.6731ZM18.6731 20H24.0065V22.6667H18.6731V20Z" fill={location.pathname === "/Dashboard/PaymentOptions" ? "#2975CA" : "#919CA5"} />
              </svg>
            )
          },
          {
            title: "Manage Subscription",
            itemId: "/Dashboard/ManageSubscription",
            elemBefore: () => (
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <path d="M14.0003 3.33317C10.3356 3.33317 7.10043 5.18152 5.17956 7.99984H8.66699V10.6665H0.666992V2.6665H3.33366V5.99898C5.76541 2.76205 9.63718 0.666504 14.0003 0.666504C21.3641 0.666504 27.3337 6.63604 27.3337 13.9998H24.667C24.667 8.1088 19.8914 3.33317 14.0003 3.33317ZM3.33366 13.9998C3.33366 19.8909 8.10928 24.6665 14.0003 24.6665C17.6651 24.6665 20.9002 22.8181 22.8211 19.9998H19.3337V17.3332H27.3337V25.3332H24.667V22.0006C22.2353 25.2376 18.3635 27.3332 14.0003 27.3332C6.63653 27.3332 0.666992 21.3636 0.666992 13.9998H3.33366Z" fill={(location.pathname === "/Dashboard/ManageSubscription" || location.pathname === "/Dashboard/Manage/Change/Plan") ? "#2975CA" : "#919CA5"} />
              </svg>
            )
          }
        ]}
      />
    </StyledSidebar>
  )
}

export default Sidebar
