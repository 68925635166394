import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import ContinueButton from '../../../Components/Buttons/ContinueButton/ContinueButton';
import url from "../../../utils/services/urls.json"
import './ManagerSubscription.scss'
import { getMethodWithToken, postMethodWithToken } from '../../../utils/services/apis';
import { toast } from 'react-toastify';
import { getUserId, navigateLogin } from '../../../utils/services/common';
import Modal from "react-modal"

const ManagerSubscriptionSection = styled.div`
font-family: Proxima_nova_reg;
margin-top: 20px;
`

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "710px",
        height: "265px",
    },
};

const ManagerSubscription = () => {
    const navigate = useNavigate();
    const moment = require('moment-timezone');
    const [subscriptionList, setSubscriptionList] = useState([])
    const [subscriptionData, setSubscriptionData] = useState([])
    const [selectedPlanId, setSelectedPlanId] = useState("")
    const [flag, setFlag] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [subscriptionPrice, setSubscriptionPrice] = useState("")
    const [monthlyPlan, setMonthlyPlan] = useState(false)
    useEffect(() => {
        getSubscriptionDetail()
    }, [])

    const getSubscriptionDetail = async () => {
        await getMethodWithToken(url.getSubscriptionDetail).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setSubscriptionData(response?.data)
                if (response?.data && response?.data.length > 0) {
                    localStorage.setItem("subscriptionDetail", JSON?.stringify(response?.data?.[0]))
                } else if (response?.data?.length === 0) {
                    setFlag(true)
                }
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }
    useEffect(() => {
        if (flag) {
            getSubscriptionList()
        }
    }, [flag])
    const getSubscriptionList = async () => {
        await getMethodWithToken(url.getSubscriptionManager).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                let changeSubscriptionList = response?.data.slice(0).reverse().map((val) => {
                    return val;
                })
                setSubscriptionList(changeSubscriptionList)
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }
    const opneCancelModal = () => {
        setOpenModal(true)
    }
    const closeCancelModal = () => {
        setOpenModal(false)
    }
    const getPlanNameUpparCase = (planName) => {
        const words = planName.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }
    const handleCancelSubscription = async () => {
        let body = {
            "payment_id": subscriptionData?.[0]?.payment_id,
            "user_id": getUserId(),
            "is_cancel": true
        }
        await postMethodWithToken(url.postCancelSubscription, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.success)
                setOpenModal(false)
                getSubscriptionDetail()
            } else {
                toast.error(response?.data?.success)
            }

        })
    }
    const handleChangePlan = () => {
        navigate(`/Dashboard/Manage/Change/Plan`)
    }

    const handleSubscriptionButton = (e) => {
        setSelectedPlanId(e.id)
        localStorage.setItem("subscriptionPrice", e?.pricePerListing?.slice(1))
        setSubscriptionPrice(e?.pricePerListing?.slice(1))
        if (e?.name === "Monthly") {
            setMonthlyPlan(true)
        } else {
            setMonthlyPlan(false)
        }
    }

    const handleAddSubscription = async () => {
        let body = {
            "user_id": getUserId(),
            "masterSubscrption": selectedPlanId,
            "price": subscriptionPrice,
            "per_month": monthlyPlan
        }
        await postMethodWithToken(url.postSubscription, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/Dashboard/Checkout/Payment`)
            } else {
                toast.error(response?.data?.success)
            }
        })
    }

    return (
        <>
            <ManagerSubscriptionSection>
                {subscriptionData && subscriptionData.length > 0 ? subscriptionData && subscriptionData.map((item, index) => {
                    return (
                        <>
                            <div className="subscription-container">
                                <div className="current-heading">Current Plan</div>
                                <hr />
                                <div className="monthly-heading">{item?.per_month === true ? "Monthly Plan" : "Yearly Plan"}</div>
                                <div className="d-flex mt-3" >
                                    <div className="price-manager-heading" >${item?.price}</div>
                                    <div className="month-manager-heading">/ {item?.per_month === true ? "month" : "year"}</div>
                                </div>
                                <Row className="mt-3">
                                    <Col lg={3}>
                                        <div className="payment-description">Active since</div>
                                        <div className="date-description">{moment.tz(item?.propertyAvailfrom, item?.propertyAvailfrom).format("DD MMMM YYYY")}</div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="payment-description">End Date</div>
                                        <div className="date-description">{moment.tz(item?.end_date, item?.end_date).format("DD MMMM YYYY")}</div>
                                    </Col>
                                </Row>
                                <div class="d-flex justify-content-end mt-2">
                                    <ContinueButton text={'Cancel Subscription'} onClick={() => opneCancelModal()} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontWeight={500} width={"217px"} />
                                    <ContinueButton text={'Change Plan'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'217px'} borderRadius={"4px"} fontWeight={600} onClick={() => handleChangePlan()} />
                                </div>
                            </div>
                        </>
                    )
                })
                    :
                    <div className="subscription-package-container">
                        <div className="current-heading">Current Plan</div>
                        <hr />
                        <div class="d-flex justify-content-center active-heading">No Active Plan</div>
                        <div class="d-flex justify-content-center active-description">Subscribe to start managing properties</div>
                        <div className="d-flex justify-content-center change-plan-heading">Change Plan</div>
                        <div className="d-flex justify-content-center change-plan-description">Select a Subscription Package</div>
                        <div className='d-flex Manager-Button'>
                            {subscriptionList && subscriptionList.length > 0 && subscriptionList.map((data, index) => {
                                return (
                                    <div className="Manager-SubscriptionButton" style={{ border: data?.id === selectedPlanId ? "2px solid #0075CA" : (data?.name === "Monthly" ? "2px solid #505050" : "2px solid #E3A301") }} onClick={() => handleSubscriptionButton(data)}>
                                        <div className='d-flex justify-content-center subscription-button' style={{ color: data?.name === "Monthly" ? "#505050" : "#E3A301" }}  >{data?.name?.length > 0 && getPlanNameUpparCase(data?.name)}
                                        </div>
                                        <div className="d-flex justify-content-center subscription-price" style={{ color: data?.name === "Monthly" ? "#0075CA" : "#E3A301" }}>{data?.pricePerListing}</div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="d-flex justify-content-center Manager-freeTrial">Get 14 days free once you add your card (only valid once)</div>
                        <div className="d-flex justify-content-end checkout-btn">
                            <ContinueButton text="Continue to Checkout" onClick={() => handleAddSubscription()} color={'#fff'} backgroundColor={selectedPlanId !== "" ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={selectedPlanId === ""} />
                        </div>
                    </div>
                }
            </ManagerSubscriptionSection>
            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="d-flex justify-content-center">
                    <div className="modal-subscription-heading">Are you sure you want to cancel your subscription?</div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="modal-subscription-description">You can still use the remaining listings after you cancel the subscription</div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="d-flex" style={{ marginTop: "60px" }}>
                        <div className="stay-heading" onClick={() => closeCancelModal()}>Stay Subscribed</div>
                        <div>
                            <ContinueButton text="Proceed to Cancel Subscription" color={'#fff'} onClick={() => handleCancelSubscription()} width={"300px"} borderRadius={"4px"} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ManagerSubscription
