import React, { useEffect, useState } from 'react';
import { InputAdornment, } from "@material-ui/core";
// import TextField from '@mui/material/TextField';
// import { IconButton } from '@mui/material';
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
// import SearchIcon from '@mui/icons-material/Search';
import url from "../../../utils/services/urls.json"
import { getMethodWithToken, postMethodWithToken, putMethodWithToken } from "../../../utils/services/apis"
import SendIcon from "../../../assets/images/searchproperty/send.svg"
import Bedroom from "../../../assets/images/searchproperty/Bed.svg"
import Bathroom from "../../../assets/images/searchproperty/bath-chat.svg"
import "./ChatList.scss"
import { useLocation, useNavigate } from 'react-router-dom';
import { navigateLogin } from '../../../utils/services/common';

const ChatList = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const jsonPattern = /^\s*(\{.*\}|\[.*\])\s*$/;
    const [managerChatList, setManagerChatList] = useState([])
    // const [searchInput, setSearchInput] = useState("")
    const [searchListId, setSearchListId] = useState("")
    const [propertyId, setPropertyId] = useState("")
    const [receiverPropertyDetail, setReceiverPropertyDetail] = useState([]);
    const [count, setCount] = useState("")
    const [chatComponent, setChatComponent] = useState(false)
    const message = []

    // const handleSearchInputChange = (event) => {
    //     setSearchInput(event.target.value)
    // }

    useEffect(() => {
        getChatMangagerList()
        const intervalId = setInterval(() => {
            getChatMangagerList()
        }, 2000);

        return () => clearInterval(intervalId);
    }, [location.pathname === "/Dashboard/Chats"])

    const getChatMangagerList = async () => {
        await getMethodWithToken(url.getChatManagerList).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setManagerChatList(response?.data)
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }
    const handleChatDetail = async (managerId, propertyId) => {
        setChatComponent(true)
        setSearchListId(managerId)
        setPropertyId(propertyId)
        await putMethodWithToken(url.managerMessageUpdate + managerId + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                handleChatManagerHistory(managerId, propertyId)
            }
        })
    }
    const handleChatManagerHistory = async (managerId) => {
        await getMethodWithToken(url.chatManagerHistory + managerId + "/").then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setReceiverPropertyDetail(response?.data);
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }

    const handleChange = (event) => {
        setCount(event.target.value)
    }

    const handleMessage = async (id, propertyId, send_message) => {
        let body = {
            receiver: id,
            propertyMaster: propertyId,
            content: send_message
        }
        await postMethodWithToken(url.sendMessage, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                message.push(response?.data?.content)
                setCount('')
                handleChatManagerHistory(id, propertyId)
            }
        })
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleMessage(searchListId, propertyId, count);
        }
    };

    const JsonparseData = (data) => {
        return (
            JSON.parse(data)
        )
    };
    return (
        <div className="d-flex">
            <div className="chat-container">
                <div style={{ fontSize: "20px", fontWeight: 400, marginLeft: "20px" }} >Messages</div>
                {/* <div>
                    <TextField
                        id="outlined-search"
                        label="Search..."
                        variant="outlined"
                        value={searchInput}
                        onChange={(e) => handleSearchInputChange(e)}
                        style={{ width: "426px", background: "#ffffff", marginTop: "10px" }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <div className="search-btn" style={{ margin: "5px -4px 5px 0" }}>
                                            <div>
                                                <SearchIcon style={{ color: "white", width: "25px", height: "25px" }} />
                                            </div>
                                            <div style={{ color: "white", fontSize: "20px", fontWeight: 500, paddingLeft: "7px" }} >Search</div>
                                        </div>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </div> */}
                <div className={managerChatList && managerChatList.length > 0 ? "chat-seaction" : "manager-chat-without-result"}>
                    {managerChatList && managerChatList.length > 0 ?
                        managerChatList.map((item, index) => {
                            return (
                                <div className="d-flex justify-content-between" style={{ background: (searchListId === item?.other_user?.id) ? "#D9EFFF" : "", paddingBottom: "5px", cursor: "pointer", borderBottom: "0.5px solid rgb(217, 217, 217)" }} onClick={() => handleChatDetail(item?.other_user?.id, item?.propertyId)} >
                                    <div className="d-flex" style={{ paddingLeft: "15px", paddingTop: "10px" }}>
                                        <div>
                                            <img src={item?.other_user?.profilePic?.file} alt="" style={{ width: "60px", height: "70px", borderRadius: "50%" }} />
                                        </div>
                                        <div className="users-heading" >
                                            <div className="user-main-heading" >{item?.other_user?.name}</div>
                                            <div className="user-heading">{item?.other_user?.organization}</div>
                                        </div>
                                    </div>
                                    <div className="request-profile">{item?.message_count}</div>
                                </div>
                            )
                        })
                        :
                        <div className="result-heading" >No results found</div>
                    }
                </div>
            </div>
            {chatComponent &&
                <div style={{ marginTop: "10px" }}>
                    <div className="chat-heading">Chats</div>
                    <div className="chat-contact-main" >
                        <div className="chat-detail-container" >
                            {receiverPropertyDetail.length > 0 && receiverPropertyDetail.map((data, index) => (
                                jsonPattern.test(data?.content) ?
                                    <div className="chat-contact-left" >
                                        <div className="d-flex" >
                                            <div className="listing-user-heading" >${JsonparseData(data?.content)?.price}/</div>
                                            <div className="listing-user-month">{JsonparseData(data?.content)?.per_month}</div>
                                        </div>
                                        <div className="chat-description">{JsonparseData(data?.content)?.address}</div>
                                        <div className="d-flex" style={{ paddingTop: "7px" }}>
                                            <div className="d-flex">
                                                <div>
                                                    <img src={Bedroom} alt="" />
                                                </div>
                                                <div className="chat-bed-heading">{JsonparseData(data?.content)?.bedroom} Bedrooms</div>
                                            </div>
                                            <div className="d-flex justify-content-end" style={{ paddingLeft: "15px" }}>
                                                <div>
                                                    <img src={Bathroom} alt="" />
                                                </div>
                                                <div className="chat-bed-heading">{JsonparseData(data?.content)?.bathroom} Bedrooms</div>
                                            </div>
                                        </div>
                                        <div className="d-flex" style={{ paddingTop: "5px" }}>
                                            <div className="chat-sqare">{JsonparseData(data?.content)?.propertySize} <span>sq/ft</span></div>
                                            <div className="chat-sqare" style={{ paddingLeft: "30px" }}>{JsonparseData(data?.content)?.propertyType}</div>
                                        </div>
                                    </div>
                                    : data?.direction === "received" ? (
                                        <div className="chat-contact-left-2" >
                                            <div className="chat-contact-heading">{data?.content}</div>
                                        </div>
                                    ) : <div className="d-flex justify-content-end">
                                        <div className="chat-contact-right" >
                                            <div className="chat-contact-right-heading">{data?.content}</div>
                                        </div>
                                    </div>

                            ))}
                        </div>
                        <div style={{ marginTop: "25px", width: "100%", }}>
                            <FormControl fullWidth variant="outlined">
                                <FilledInput
                                    id="filled-adornment-weight"
                                    value={count}
                                    onChange={(e) => handleChange(e)}
                                    onKeyDown={handleKeyDown}
                                    endAdornment={<InputAdornment position="end">
                                        <img src={SendIcon} alt="" style={{ cursor: "pointer", paddingRight: "10px" }} onClick={() => handleMessage(searchListId, propertyId, count)} />
                                    </InputAdornment>}
                                    aria-describedby="filled-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                            </FormControl>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default ChatList
