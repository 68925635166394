import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SuccessScreen from '../Components/SuccessScreen/SuccessScreen';
import CreatePassword from '../screens/CreatePassword/CreatePassword';
import ForgotPassword from '../screens/ForgotPassword/ForgotPassword';
import ResetPassword from '../screens/ResetPassword/ResetPassword';
import Dashboard from '../screens/Dashboard/Dashboard';
import Home from '../screens/Home/Home';
import Login from '../screens/Login/Login';
import Registration from '../screens/Registration/Registration';
import ChangePlan from '../screens/ManageSubscriptions/ChangePlan/ChangePlan';
import CheckoutPayment from '../screens/CheckoutPayment/CheckoutPayment';
import { getToken } from '../utils/services/common';
import ListingRequest from '../screens/AssignList/ListingRequest/ListingRequest';
import ListingDetails from '../Components/commonComponents/ListingDetails/ListingDetails';


export default function Rout() {
  const publicRoutes = [
    {
      path: "/",
      element: <Home />
    },
    {
      path: "/:value",
      element: <Home />
    },
    {
      path: "/:value/Registration",
      element: <Registration />
    },
    {
      path: "/CreatePassword",
      element: <CreatePassword />
    },
    {
      path: "/:value/ForgotPassword",
      element: <ForgotPassword />
    },
    {
      path: "/ResetPassword",
      element: <ResetPassword />
    },
    {
      path: "/:value/:childValue/Success",
      element: <SuccessScreen />
    },
    {
      path: "/:value/Login",
      element: <Login />
    },

  ]
  function PublicRoute({ element }) {
    const isLoggedIn = getToken();
    return isLoggedIn ? <Navigate to="/Dashboard/AssignedListings" /> : element;
  }

  const privateRoutes = [
    {
      path: "/Dashboard",
      element: <Dashboard />,
      isPrivate: true,
    },
    {
      path: "/Dashboard/:value",
      element: <Dashboard />,
      isPrivate: true,
    },
    {
      path: "Dashboard/Manager/Request/Details/:id/:managerDetail",
      element: <ListingDetails />,
      isPrivate: true,
    },
    {
      path: "Dashboard/AssignedListings/Request",
      element: <ListingRequest />,
      isPrivate: true,
    },
    {
      path: "Dashboard/AssignedListings/Assign/Detail/:id",
      element: <ListingDetails />,
      isPrivate: true,
    },
    {
      path: "Dashboard/Manage/Change/Plan",
      element: <ChangePlan />,
      isPrivate: true,
    },
    {
      path: "/Dashboard/Checkout/Payment",
      element: <CheckoutPayment />,
      isPrivate: true,
    },
    {
      path: "/Dashboard/Checkout/Payment/:assignList",
      element: <CheckoutPayment />,
      isPrivate: true,
    },
    {
      path: "/Dashboard/PropertyOwners/detail/:id",
      element: <ListingDetails />,
      isPrivate: true
    }
  ]
  function PrivateRoute({ element }) {
    const isLoggedIn = getToken();

    return isLoggedIn ? element : <Navigate to="/" />;
  }
  return (
    <>
      <Router>
        <Routes>
          {publicRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={<PublicRoute element={route.element} />} />
          ))}
          {privateRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<PrivateRoute element={route.element} />}
            />
          ))}
        </Routes>
      </Router>
    </>
  );
};
