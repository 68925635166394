import React, { useEffect, useState } from 'react';
import Navbar from "../../../Components/commonComponents/navbar/Navbar";
import { Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ArrowBack from "../../../assets/images/favoriteProperty/arrow-back.svg";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import prevArrowImage from '../../../assets/images/searchproperty/backwardarrow.svg';
import nextArrowImage from '../../../assets/images/searchproperty/forwardarrow.svg';
import Modal from "react-modal";
import closeIcon from "../../../assets/images/searchproperty/close.svg";
import FormInput from "../../../Components/Inputs/FormInput/FormInput";
import BackIcon from "../../../assets/images/searchproperty/back_month.svg"
import ForwardIcon from "../../../assets/images/searchproperty/ford_month.svg"
import url from "../../../utils/services/urls.json"
import { getMethodWithToken, postMethodWithToken } from '../../../utils/services/apis';
import Location from "../../../assets/images/searchproperty/location.svg";
import Bedroom from '../../../assets/images/searchproperty/Bed.svg';
import Bathroom from '../../../assets/images/searchproperty/Bathtub.svg';
import Clock from "../../../assets/images/searchproperty/Clock.svg";
import Chair from '../../../assets/images/searchproperty/Armchair.svg';
// import Key from '../../../assets/images/searchproperty/Keys.svg';
import Car from "../../../assets/images/searchproperty/Car.svg";
import Balcony from '../../../assets/images/searchproperty/balcony.svg';
import Calender from "../../../assets/images/searchproperty/calendar_month.svg";
import Accept from "../../../assets/images/listingManager/Check.svg"
import Reject from "../../../assets/images/listingManager/X.svg"
import "./ListingDetails.scss"
import { toast } from 'react-toastify';
import { navigateLogin } from '../../../utils/services/common';

const ListingDetailsContainer = styled.div`
background: rgb(242, 242, 247);
height:auto;
font-family: Proxima_nova_reg;
`

const ListingDetails = () => {
    const moment = require('moment-timezone');
    const navigate = useNavigate()
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "35vw" : screenSize.width <= 1732 ? "40vw" : "30vw",
            height: screenSize.width <= 800 ? "55vh" : "62vh",
        },
    };

    const params = useParams()
    const today = new Date()
    const [detailsList, setDetailsList] = useState([])
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [mainImage, setMainImage] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        getAllDetailList()
    }, [])

    const getAllDetailList = async () => {
        await getMethodWithToken(url.getListViewDetail + params?.id + "/").then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setDetailsList(response?.data?.detail)
                setMainImage(response?.data?.detail?.stepFive?.[0]?.file)
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }

    const handleslidderImageClick = (index, image) => {
        setSelectedImageIndex(index);
        setMainImage(image)
    };

    const CustomPrevArrow = (props) => (
        <div {...props} className="custom-arrow prev-arrow" style={{ zIndex: openModal === true ? 0 : 1 }}>
            <img src={prevArrowImage} alt="Previous" />
        </div>
    );

    const CustomNextArrow = (props) => (
        <div {...props} className="custom-arrow next-arrow" style={{ zIndex: openModal === true ? 0 : 1 }} >
            <img src={nextArrowImage} alt="Next" />
        </div>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };
    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }
    const handleInputValue = (e) => {
        const { value } = e.target;
        const parsedDate = new Date(value);
        if (!isNaN(parsedDate.getTime())) {
            setSelectedDate(parsedDate);
        }
    };
    const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
        const monthYear = date.toLocaleDateString(undefined, {
            month: 'long',
            year: 'numeric',
        });

        const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

        return (
            <div className="custom-header">
                <div className='date_month'>
                    <p className='monthyear'>{monthYear}</p>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <img src={BackIcon} onClick={decreaseMonth} alt='back' style={{ cursor: "pointer" }} />
                        <img src={ForwardIcon} onClick={increaseMonth} alt='fordward' style={{ cursor: "pointer" }} />
                    </div>

                </div>
                <div style={{ fontSize: '20px', justifyContent: "space-between", display: 'flex', fontFamily: 'Proxima_nove_reg' }}>
                    {days.map((day, index) => (
                        <span key={index}>{day}</span>
                    ))}
                </div>
            </div>
        );
    };
    const customDate = {
        '.react-datepicker__day-names': {
            display: 'none',
        }
    }
    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }

    const handleRequest = async (propertyId, action) => {
        let managingRequestApi
        if (action === 1) {
            let action = "accept"
            managingRequestApi = `${url.ManagingRequestAcceptReject}?action=${action}`
        } else if (action === 0) {
            let action = "reject"
            managingRequestApi = `${url.ManagingRequestAcceptReject}?action=${action}`
        }
        let body = {
            "propertyId": propertyId
        }
        await postMethodWithToken(managingRequestApi, body).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                navigate("/Dashboard/ManagingRequests")
                toast.success(response?.data?.success)
            }
        })
    }
    return (
        <>
            <div style={{ overflowX: "hidden" }} className="searchdetail">
                <div style={{ background: "#f2f2f7", height: "fit-content" }}>
                    <Navbar />
                </div>
                <ListingDetailsContainer>
                    <Row style={{ padding: "80px 25px 20px 25px" }}>
                        <Col lg={1}>
                            <div className="arrow-box" onClick={() => navigate(-1)}>
                                <img src={ArrowBack} alt="" />
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div>
                                <img src={mainImage} alt="Mainimage" style={{ width: "100%", height: "460px" }} />
                                <Slider {...settings} >
                                    {detailsList?.stepFive && detailsList?.stepFive.length > 0 && detailsList?.stepFive.map((item, index) => {
                                        return (
                                            <div className={`carousel-item ${selectedImageIndex === index ? 'selected' : ''}`} onClick={() => handleslidderImageClick(index, item?.file)}>
                                                <img src={item?.file} alt="carouselImage" style={{ height: "90px", width: "180px" }} />
                                            </div>

                                        )
                                    })}

                                </Slider>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="property-detail-section">
                                <div className="d-flex">
                                    <div>
                                        <img src={detailsList?.Property_owner?.[0]?.profilePic} alt="" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                                    </div>
                                    <div>
                                        <div className="d-flex" style={{ paddingLeft: "7px" }}>
                                            <div className="user-heading">{detailsList?.Property_owner?.[0]?.name && getUpparCaseName(detailsList?.Property_owner?.[0]?.name)}</div>
                                            <div className="user-dot">.</div>
                                            <div className="property-owner">Property Owner</div>
                                        </div>
                                        {/* <div style={{ color: "#707070", paddingLeft: "7px", fontSize: "16px", textAlign: "start" }}>{detailsList?.Property_owner?.[0]?.orgnization}</div> */}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                    <div className="d-flex align-items-center">
                                        <img src={Calender} alt="" style={{ cursor: "pointer" }} onClick={() => handleOpenModal()} />
                                        <div className="availability-heading">Check&nbsp;Availability</div>
                                    </div>
                                    {params?.managerDetail &&
                                        <div className="d-flex">
                                            <div className="accept-box" onClick={() => handleRequest(detailsList?.id, 1)}>
                                                <img src={Accept} alt="Accept" />
                                                <div className="accept-heading">Accept Request</div>
                                            </div>
                                            <div className="reject-box" onClick={() => handleRequest(detailsList?.id, 0)}>
                                                <img src={Reject} alt="Accept" />
                                                <div className="reject-heading">Reject Request</div>
                                            </div>
                                        </div>
                                    }

                                </div>
                                <hr />
                                <div className="deposit-section">
                                    <Row>
                                        <Col lg={4}>
                                            <div className="amount-seacrh">Rent</div>
                                            <div className="deposit-price" style={{ borderRight: "1px solid rgb(183, 183, 183)" }}>${detailsList?.stepSix?.price}<span style={{ fontSize: "16px", fontWeight: "400", color: "#707070", paddingLeft: "10px", paddingTop: "10px" }}>/{detailsList?.stepSix?.per_day === true ? "day" : "month"}</span></div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="amount-seacrh">Deposit</div>
                                            <div className="deposit-price" style={{ borderRight: "1px solid rgb(183, 183, 183)" }}>${detailsList?.stepSix?.deposit_amount}</div>
                                        </Col>
                                        <Col lg={3} >
                                            <div className="amount-seacrh">Built-up</div>
                                            <div className="deposit-price">{detailsList?.stepTwo?.propertySize} sqft</div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="d-flex mt-3">
                                    <img src={Location} alt="Location" />
                                    <div className="address-heading">{detailsList?.stepThree?.locality}</div>
                                </div>
                                <div>
                                    <div className="property-heading">Property Details</div>
                                    <Row>
                                        <Col lg={3} style={{ borderRight: "0.3px solid #D3D3D3", borderBottom: "0.3px solid #D3D3D3" }}>
                                            <div className="d-flex justify-content-center property-box">
                                                <img src={Bedroom} alt="Bedroom" />
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="property-sub-heading">{detailsList?.stepTwo?.bedroomCount} Bedrooms</div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={3} style={{ borderRight: "0.3px solid #D3D3D3", borderBottom: "0.3px solid #D3D3D3" }}>
                                            <div className="d-flex justify-content-center property-box">
                                                <img src={Bathroom} alt="Bedroom" />
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="property-sub-heading">{detailsList?.stepOne?.propertyType?.name === "Room" ? detailsList?.stepTwo?.room_sharing?.name : detailsList?.stepTwo?.bathroomCount} Bathroom</div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={3} style={{ borderRight: "0.3px solid #D3D3D3", borderBottom: "0.3px solid #D3D3D3" }}>
                                            <div className="d-flex justify-content-center property-box">
                                                <img src={Balcony} alt="Bedroom" />
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="property-sub-heading">{detailsList?.stepTwo?.balconyCount} Balcony</div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={3} style={{ borderBottom: "0.3px solid #D3D3D3" }}>
                                            <div className="d-flex justify-content-center property-box">
                                                <img src={detailsList?.stepOne?.propertyType?.image} alt="Bedroom" style={{ width: "30px", height: "20px" }} />
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="property-sub-heading">{detailsList?.stepOne?.propertyType?.name} </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={3} style={{ borderRight: "0.3px solid #D3D3D3" }}>
                                            <div className="d-flex justify-content-center property-box">
                                                <img src={Chair} alt="Bedroom" />
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="property-sub-heading">{detailsList?.stepTwo?.furnishType?.name?.length > 0 && getUpparCaseName(detailsList?.stepTwo?.furnishType?.name)}</div>
                                                </div>
                                            </div>
                                        </Col>
                                        {/* <Col lg={3} style={{ borderRight: "0.3px solid #D3D3D3" }}>
                                            <div className="d-flex justify-content-center property-box">
                                                <img src={Key} alt="Bedroom" />
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="property-sub-heading">Immediate Possession</div>
                                                </div>
                                            </div>
                                        </Col> */}
                                        <Col lg={3} style={{ borderRight: "0.3px solid #D3D3D3" }}>
                                            <div className="d-flex justify-content-center property-box">
                                                <img src={Car} alt="Bedroom" />
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="property-sub-heading">{detailsList?.stepTwo?.parkingType?.name?.length > 0 && getUpparCaseName(detailsList?.stepTwo?.parkingType?.name)}</div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={3} style={{ borderRight: "0.3px solid #D3D3D3" }}>
                                            <div className="d-flex justify-content-center property-box">
                                                <img src={Clock} alt="Bedroom" />
                                            </div>
                                            <div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="property-sub-heading">{moment.tz(detailsList?.stepSix?.propertyAvailfrom, detailsList?.stepSix?.propertyAvailfrom).format("YYYY-MM-DD")}</div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                {/* <div>
                                    <Row style={{ marginTop: "20px" }}>
                                        <Col lg={4}>
                                            <div className="feature-property-heading">Preferred Tenants</div>
                                            <div className="feature-property-description">Bachelors</div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="feature-property-heading">Property age</div>
                                            <div className="feature-property-description">7 years</div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="feature-property-heading">Floor</div>
                                            <div className="feature-property-description">15th</div>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: "30px" }}>
                                        <Col lg={4}>
                                            <div className="feature-property-heading">Water Supply</div>
                                            <div className="feature-property-description">Bore-well</div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="feature-property-heading">Monthly Maintenance</div>
                                            <div className="feature-property-description">None</div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="feature-property-heading">Facing</div>
                                            <div className="feature-property-description">East</div>
                                        </Col>
                                    </Row>
                                </div> */}
                                <div className="feature-property-heading" style={{ marginTop: "20px" }}>Amenities</div>
                                {detailsList?.stepFour && detailsList?.stepFour.length > 0 && detailsList?.stepFour.map((item, index) => {
                                    return (
                                        <div className="amenitie-box" >
                                            <div className="amenitie-select d-flex">
                                                <div >
                                                    <img src={item?.image} alt="" style={{ paddingLeft: "10px", width: "50px", height: "50px" }} />
                                                </div>
                                                <div className="amenitie-select-heading" >{item?.name}</div>
                                            </div>
                                        </div>

                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                </ListingDetailsContainer>
            </div>
            <Modal style={customStyles} isOpen={openModal} overlayClassName="myoverlay" >
                <div className="modal-container" >
                    <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
                        <div className="modal-main-heading  calender_header" > <p style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }}>Availability</p>
                            <img src={closeIcon} onClick={handleCloseModal} style={{ cursor: 'pointer' }} alt='close' /></div>
                        <div className='inputField_calender'>
                            <FormInput type='text' name={'Jump To'} id={'name'} value={selectedDate ? selectedDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }) : ''} onChange={(e) => handleInputValue(e)} />
                        </div>

                    </div>
                    <div className='main_calender' style={{ borderBottom: "1px solid #EAEAEA" }}>
                        <DatePicker
                            showNextMonths={false} minDate={today} style={customDate} selected={selectedDate} onChange={(date) => setSelectedDate(date)} inline dateFormat="MMMM yyyy" dayClassName={(date) => {
                                return date.getDay() === 0 || date.getDay() === 6 ? 'weekend' : '';
                            }} renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />)}
                            locale={enGB} formatWeekDayShort={(locale, dayOfWeek) => { return new Intl.DateTimeFormat('en', { weekday: 'short' }).format(new Date(0, 0, dayOfWeek)); }}
                        />
                    </div>
                    <div className="d-flex" style={{ marginTop: "15px" }}>
                        <div className="slots" />
                        <p className='footer_para'>Available Slots</p>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ListingDetails
